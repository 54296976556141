import React, {useEffect} from "react";
import {withRouter} from 'react-router-dom';


function TenantDocument(props) {
    useEffect(() => {
    }, []);

    return (
        <div>
            <h1>Document</h1>
            <h2>Check out our offerings in the sidebar!</h2>
        </div>
    );
}

export default withRouter(TenantDocument)