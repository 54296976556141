import React, {useCallback, useEffect, useState} from "react";
import APIservices from "../../../services/APIservices";
import Loader from "../../common/Loader";
import {Button, Card, FormControl, InputGroup} from "react-bootstrap";
import ErrorPage from "../../common/ErrorPage";
import {withRouter} from "react-router-dom";

function Form(props) {

    const [state, setState] = useState({
        isLoading: false,
        formData: {
            username: ""
        },
        error: {
            show: false,
            data: {
                code: "",
                message: ""
            }
        }
    });

    let alterState = useCallback((key, value) => {
        state[key] = value.constructor === String ? value.trim() : value;
        setState({...state})
    }, [state, setState]);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let handleUserInput = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let formData = state.formData;
        formData[name] = value
        alterState('formData', formData)
    }

    let inputValidation = () => {
        let isValid = true;

        let errors = props.errors;

        if (state.formData.username.length > 0) {
            errors.password = ""
        } else {
            errors.password = "Invalid Username"
            isValid = false;
        }

        props.alterState("errors", errors);
        props.alterState("isError", !isValid);
        return isValid;
    }

    let submitForm = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!inputValidation()) {
            return false;
        } else {
            alterState('isLoading', true);
            const response = await APIservices.forgotPassword(state.formData);
            if (response.data.data.error === undefined) {
                props.history.push("/login");
            } else {
                props.alterState("errors", {username:response.data.data.error.message});
                props.alterState("isError", true);
                alterState('isLoading', false);
                // alterState('error', {data: response.data.data.error, show: true});
            }
        }
    }

    if (state.error.show)
        return <ErrorPage
            code={state.error.data.code}
            message={state.error.data.message}
        />
    else if (state.isLoading)
        return <Loader/>
    else
        return (
            <div>
                <Card>
                    <Card.Body>
                        <Card.Title>Forgot Password</Card.Title>
                        <div>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Username &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    id="username"
                                    name="username"
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    type="text"
                                    value={state.formData.username}
                                    onChange={handleUserInput}
                                />
                            </InputGroup>


                        </div>
                        <div>
                            <Button onClick={submitForm} variant="primary">
                                Sent Mail
                            </Button>{' '}
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
}

export default withRouter(Form)