import React, {useCallback, useEffect, useState} from "react";
import {withRouter} from 'react-router-dom';
import APIservices from "../../../services/APIservices";
import Loader from "../../common/Loader";
import TableData from "../../common/TableData";

function TenantRent(props) {

    let viewPdf = async (row, operation) => {
        let url = typeof operation.link === "function" ? operation.link(row) : row[operation.link];
        APIservices.downloadFile(url).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            let fileName = response.headers.hasOwnProperty('file-name') ? response.headers['file-name'] : row.invoice_no + '.pdf';
            let link = document.createElement("a");
            link.href = url;
            link.setAttribute('download', fileName);
            link.click();
        });
    }

    let showReceiptButton = (rowData) => {
        return rowData.receipt_link !== ''
    }

    const [state, setState] = useState(
        {
            isLoading: true,
            filters: {
                page: {
                    size: 10,
                    current: 1
                }
            },
            rentData: [],
            columns: {
                fields: [
                    {
                        dataField: 'uuid',
                        text: 'UUID',
                        hidden: true,
                    },
                    {
                        dataField: 'invoice_type',
                        text: 'Invoice Type',
                    },
                    {
                        dataField: 'invoice_no',
                        text: 'Invoice Number',
                    },
                    {
                        dataField: 'date',
                        text: 'Date'
                    },
                    {
                        dataField: 'amount',
                        text: 'Amount'
                    },
                    {
                        dataField: 'landlord_name',
                        text: 'Landlord Name'
                    },
                    {
                        dataField: 'payment_status',
                        text: 'Payment Status'
                    }
                ],
                operation: [
                    {
                        icon: 'fa-download',
                        text: 'Invoice',
                        link: 'invoice_link',
                        onClick: viewPdf
                    },
                    {
                        hidden: showReceiptButton,
                        icon: 'fa-download',
                        text: 'Receipt',
                        link: 'receipt_link',
                        onClick: viewPdf
                    }
                ],
            }
        }
    );


    let alterState = useCallback((key, value) => {
        state[key] = value.constructor === String ? value.trim() : value;
        setState({...state})
    }, [state, setState]);

    useEffect(() => {
        APIservices.getTenantRent(state.filters).then((response) => {
            if (response.data.data.error === undefined) {
                alterState('rentData', response.data.data);
                alterState('isLoading', false);
            } else {
                console.log(response.data);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (state.isLoading) {
        return (<Loader/>)
    } else {
        return (
            <div>
                <h1>Rent</h1>
                <TableData headers={state.columns} rows={state.rentData}/>
            </div>
        );
    }
}

export default withRouter(TenantRent)