import axios from 'axios';

const baseURL = process.env.REACT_APP_BACKEND_BASE_API_URL
const AppData = JSON.parse(localStorage.getItem('app_data') || "{}");


export default {
    postData: function (url, data) {
        let headers = {
            "App-Token-Key": AppData.token || "",
            "Content-Type": "application/json",
        };
        return axios.post(baseURL + url, data, {headers: headers});
    },

    getData: function (url, data) {
        let headers = {
            "App-Token-Key": AppData.token || "",
        };
        return axios.get(baseURL + url, {params: data, headers: headers});
    },

    getFile: function (url) {
        let headers = {
            "App-Token-Key": AppData.token || "",
        };
        return axios.get(url, {
            headers: headers,
            responseType: 'blob' // important
        });
    }
}