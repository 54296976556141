import React from "react";
import {Link} from "react-router-dom";

export default function TopNavigation(props) {

    return (
        <div>
            <nav style={{
                paddingLeft: "6rem",
                paddingTop: "1rem"
            }} className="navbar navbar-expand-lg navbar-light">
                <Link className="navbar-brand" to={"/"}>
                    <img src="https://blog.mmtech.dev/publish/8d9c4242-81fb-488f-aa22-9fdee25753a2" width="80" height="80"
                         className="d-inline-block" alt=""/>
                    {process.env.REACT_APP_APPLICATION_NAME}
                </Link>
                {/*<div className="collapse navbar-collapse" id="navbarToggler">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <Link className="nav-link" to={"/sign-in"}>Login</Link>
                            </li>
                        </ul>
                    </div>*/}
            </nav>
        </div>
    );
}