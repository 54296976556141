import React, {useCallback, useEffect, useState} from "react";
import APIservices from "../../../services/APIservices";
import Loader from "../../common/Loader";
import {Button, Card, FormControl, InputGroup} from "react-bootstrap";
import ErrorPage from "../../common/ErrorPage";
import {withRouter} from "react-router-dom";

function Form(props) {

    const [state, setState] = useState({
        isLoading: true,
        defaultData: {
            tenant: {
                uuid: "",
                name: ""
            },
            property: {
                name: ""
            }
        },
        formData: {
            password: "",
            password_confirmation: ""
        },
        error: {
            show: false,
            data: {
                code: "",
                message: ""
            }
        }
    });

    let alterState = useCallback((key, value) => {
        state[key] = value.constructor === String ? value.trim() : value;
        setState({...state})
    }, [state, setState]);

    let handleOnLoad = useCallback(async () => {
        const response = await APIservices.getResetPasswordCodeDetails(props.code)
        if (response.data.data.error === undefined) {
            alterState('defaultData', response.data.data);
        } else {
            alterState('error', {data: response.data.data.error, show: true});
        }
    }, [props, alterState]);

    useEffect(() => {
        handleOnLoad().then(() => {
            alterState('isLoading', false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let handleUserInput = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let formData = state.formData;
        formData[name] = value
        alterState('formData', formData)
    }

    let inputValidation = () => {
        let isValid = true;

        let errors = props.errors;

        if (state.formData.password.length > 0) {
            errors.password = ""
        } else {
            errors.password = "Invalid Password"
            isValid = false;
        }

        if (state.formData.password_confirmation.length > 0) {
            errors.password_confirmation = ""
        } else {
            errors.password_confirmation = "Invalid Confirm Password"
            isValid = false;
        }

        if (state.formData.password_confirmation === state.formData.password) {
            errors.password_confirmation = ""
        } else {
            errors.password_confirmation = "Password and Confirm Password are not Same"
            isValid = false;
        }

        props.alterState("errors", errors);
        props.alterState("isError", !isValid);
        return isValid;
    }

    let submitForm = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!inputValidation()) {
            return false;
        } else {
            alterState('isLoading', true);
            const response = await APIservices.resetPassword(props.code, state.formData);
            if (response.data.data.error === undefined) {
                props.history.push("/login");
            } else {
                props.alterState("errors", {username:response.data.data.error.message});
                props.alterState("isError", true);
                alterState('isLoading', false);
                // alterState('error', {data: response.data.data.error, show: true});
            }
        }
    }

    if (state.error.show)
        return <ErrorPage
            code={state.error.data.code}
            message={state.error.data.message}
        />
    else if (state.isLoading)
        return <Loader/>
    else
        return (
            <div>
                <Card>
                    <Card.Body>
                        <Card.Title>Tenant Info</Card.Title>
                        <div>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="propety_name">Property Name</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    type="text"
                                    value={state.defaultData.property.name}
                                    readOnly
                                />
                            </InputGroup>

                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="tenant_name">Tenant Name &nbsp;&nbsp;</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    type="text"
                                    value={state.defaultData.tenant.name}
                                    readOnly
                                />
                            </InputGroup>

                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="tenant_name">UserName &nbsp;&nbsp;</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    type="text"
                                    value={state.defaultData.user.username}
                                    readOnly
                                />
                            </InputGroup>
                        </div>
                    </Card.Body>
                </Card>
                <br/>
                <Card>
                    <Card.Body>
                        <Card.Title>Reset Password</Card.Title>
                        <div>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Password &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    id="password"
                                    name="password"
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    type="text"
                                    value={state.formData.password}
                                    onChange={handleUserInput}
                                />
                            </InputGroup>

                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Confirm Password</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    id="password_confirmation"
                                    name="password_confirmation"
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    type="text"
                                    value={state.formData.password_confirmation}
                                    onChange={handleUserInput}
                                />
                            </InputGroup>
                        </div>
                        <div>
                            <Button onClick={submitForm} variant="primary">
                                Reset
                            </Button>{' '}
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
}

export default withRouter(Form)