import React, {useCallback, useEffect, useState} from "react";
import APIservices from "../../../services/APIservices";
import Loader from "../../common/Loader";
import {Button, Card, FormControl, InputGroup, Form} from "react-bootstrap";
import ErrorPage from "../../common/ErrorPage";
import {withRouter} from "react-router-dom";

const emailRegx = RegExp(/^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\]+)\.([a-zA-Z.]{2,})$/);

function RegistrationForm(props) {

    const [state, setState] = useState({
        isLoading: true,
        defaultData: {
            tenant: {
                uuid: "",
                name: ""
            },
            property: {
                name: ""
            }
        },
        formData: {
            username: "",
            name: "",
            mobile: "",
            email: "",
            password: "",
            communication: ""
        },
        error: {
            show: false,
            data: {
                code: "",
                message: ""
            }
        }
    });

    let alterState = useCallback((key, value) => {
        state[key] = value.constructor === String ? value.trim() : value;
        setState({...state})
    }, [state, setState]);

    let handleOnLoad = useCallback(async () => {
        const response = await APIservices.getTenantRegistration(props.code)
        if (response.data.data.error === undefined) {
            alterState('defaultData', response.data.data);
        } else {
            alterState('error', {data: response.data.data.error, show: true});
        }
    }, [props, alterState]);

    useEffect(() => {
        handleOnLoad().then(() => {
            alterState('isLoading', false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let handleUserInput = e => {
        //e.preventDefault();
        const {name, value} = e.target;
        let formData = state.formData;
        formData[name] = value
        alterState('formData', formData)
    }

    let inputValidation = () => {
        let isValid = true;

        let errors = props.errors;

        if (state.formData.email.length > 0 && emailRegx.test(state.formData.email)) {
            errors.email = ""
        } else {
            errors.email = "Invalid Email"
            isValid = false;
        }

        if (state.formData.name.length > 3) {
            errors.name = ""
        } else {
            errors.name = "Invalid Name"
            isValid = false;
        }

        if (state.formData.username.length > 3) {
            errors.username = ""
        } else {
            errors.username = "Invalid UserName, Min 4 letters"
            isValid = false;
        }

        if (state.formData.password.length > 3) {
            errors.password = ""
        } else {
            errors.password = "Invalid Password"
            isValid = false;
        }

        if (state.formData.mobile.length === 10) {
            errors.name = ""
        } else {
            errors.name = "Invalid Mobile Number"
            isValid = false;
        }

        if (state.formData.communication.length > 2) {
            errors.name = ""
        } else {
            errors.name = "Please Select Communication"
            isValid = false;
        }

        props.alterState("errors", errors);
        props.alterState("isError", !isValid);
        return isValid;
    }

    let submitForm = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        alterState('isLoading', true);
        if (!inputValidation()) {
            return false;
        } else {
            const response = await APIservices.createTenantRegistration(props.code, state.formData);
            if (response.data.data.error === undefined) {
                props.history.push("/login");
            } else {
                props.alterState("errors", {username:response.data.data.error.message});
                props.alterState("isError", true);
                alterState('isLoading', false);
                // alterState('error', {data: response.data.data.error, show: true});
            }
        }
    }

    if (state.error.show)
        return <ErrorPage
            code={state.error.data.code}
            message={state.error.data.message}
        />
    else if (state.isLoading)
        return <Loader/>
    else
        return (
            <div>
                <Card>
                    <Card.Body>
                        <Card.Title>Tenant Info</Card.Title>
                        <div>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="propety_name">Property Name</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    type="text"
                                    value={state.defaultData.property.name}
                                    readOnly
                                />
                            </InputGroup>

                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="tenant_name">Tenant Name &nbsp;&nbsp;</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    type="text"
                                    value={state.defaultData.tenant.name}
                                    readOnly
                                />
                            </InputGroup>
                        </div>
                    </Card.Body>
                </Card>
                <br/>
                <Card>
                    <Card.Body>
                        <Card.Title>Registration Form</Card.Title>
                        <div>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Username &nbsp;&nbsp;</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    id="username"
                                    name="username"
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    type="text"
                                    value={state.formData.username}
                                    onChange={handleUserInput}
                                />
                            </InputGroup>

                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Name &nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    id="name"
                                    name="name"
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    type="text"
                                    value={state.formData.name}
                                    onChange={handleUserInput}
                                />
                            </InputGroup>

                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Mobile &nbsp;+91&nbsp;</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    id="mobile"
                                    name="mobile"
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    type="text"
                                    value={state.formData.mobile}
                                    onChange={handleUserInput}
                                />
                            </InputGroup>

                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Email ID &nbsp;&nbsp; &nbsp;&nbsp;</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    id="email"
                                    name="email"
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    type="email"
                                    value={state.formData.email}
                                    onChange={handleUserInput}
                                />
                            </InputGroup>

                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text>Password &nbsp;&nbsp;</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    id="password"
                                    name="password"
                                    aria-label="Default"
                                    aria-describedby="inputGroup-sizing-default"
                                    type="password"
                                    value={state.formData.password}
                                    onChange={handleUserInput}
                                />
                            </InputGroup>

                            <InputGroup className="mb-3">
                                <InputGroup.Prepend style={{marginRight:'10px'}}>
                                    <InputGroup.Text>Send Invoice / Message To</InputGroup.Text>
                                </InputGroup.Prepend>
                                {['mobile', 'email', 'all'].map((type, index) => (
                                        <Form.Check
                                            style={{textTransform: 'capitalize'}}
                                            key={index}
                                            inline
                                            value={`${type}`}
                                            type='radio'
                                            id={`communication-${type}`}
                                            label={`${type}`}
                                            name="communication"
                                            onChange={handleUserInput}
                                            checked={type === state.formData.communication}
                                        />
                                ))}
                            </InputGroup>
                        </div>
                        <div>
                            <Button onClick={submitForm} variant="primary">
                                Register
                            </Button>{' '}
                        </div>
                    </Card.Body>
                </Card>
            </div>
        );
}

export default withRouter(RegistrationForm)