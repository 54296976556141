import API from "./API";

class APIservices {

    createTenantRegistration = async (code, data) => {
        return await API.postData('/tenant/registration/' + code, data);
    }

    getTenantRegistration = async (code) => {
        return await API.getData('/tenant/registration/' + code);
    }

    getResetPasswordCodeDetails = async (code) => {
        return await API.getData('/reset-password/' + code);
    }

    resetPassword = async (code, data) => {
        return await API.postData('/reset-password/' + code, data);
    }

    forgotPassword = async (data) => {
        return await API.postData('/forgot-password', data);
    }

    downloadFile = async (url) => {
        return await API.getFile(url);
    }

    getTenantRent = async () => {
        return await API.getData('/tenant/rent');
    }

    getDashboardInfo = async () => {
        return await API.getData('/tenant/dashboard');
    }

    login = async (formData) => {
        return await API.postData('/login', formData);
    }

    logout = async () => {
        return await API.postData('/logout', {});
    }
}

export default new APIservices();