import React, {useEffect} from 'react';
import {Switch} from "react-router-dom";
import './App.css';
import TopNavigation from './components/common/TopNavigation'
import RegisterNewTenantUser from './components/tenants/registration/RegisterNewTenantUser'
import ResetTenantUserPassword from './components/tenants/passwordReset/ResetTenantUserPassword'
import ForgotTenantUserPassword from './components/tenants/forgotPassword/ForgotTenantUserPassword'
import Login from "./components/login/Login";
import TenantDashboard from "./components/tenants/dashboard/TenantDashboard";
import TenantDocument from "./components/tenants/document/TenantDocument";
import SideBar from "./components/common/SideBar";
import {PublicRoute, PrivateRoute} from './services/Route'
import TenantRent from "./components/tenants/rent/TenantRent";
import Logout from "./components/login/Logout";

export default function App(props) {
    useEffect(() => {

    }, []);

    return (
        <div id="MainApp">
            <SideBar pageWrapId={"page-wrap"} outerContainerId={"MainApp"}/>
            <div id="page-wrap">
                <TopNavigation/>
                <div className="container">
                    <React.StrictMode>
                        <Switch>
                            <PublicRoute
                                path="/registration/:code"
                                component={RegisterNewTenantUser}
                            />

                            <PublicRoute
                                path="/reset-password/:code"
                                component={ResetTenantUserPassword}
                            />

                            <PublicRoute
                                path="/forgot-password"
                                component={ForgotTenantUserPassword}
                            />

                            <PublicRoute
                                path="/login"
                                component={Login}
                            />

                            <PrivateRoute
                                exact
                                path="/"
                                component={TenantDashboard}
                            />

                            <PrivateRoute
                                path="/document"
                                component={TenantDocument}
                            />

                            <PrivateRoute
                                path="/rent"
                                component={TenantRent}
                            />

                            <PrivateRoute
                                path="/logout"
                                component={Logout}
                            />
                        </Switch>
                    </React.StrictMode>
                </div>
            </div>
        </div>
    );
}
