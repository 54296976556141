import React from "react";
import {Alert, Button, ListGroup} from "react-bootstrap";

export default function AppBody(props) {

    return (
        <Alert show={props.isError} variant="danger">
            <Alert.Heading>Validation Error</Alert.Heading>
            <ListGroup>
                {
                    Object.keys(props.errors).map((item, index) => {
                        if (props.errors[item].length !== 0) {
                            return (
                                <ListGroup.Item variant="danger" key={index}> {props.errors[item]} </ListGroup.Item>
                            )
                        }
                        return null;
                    })
                }
            </ListGroup>
            <hr/>
            <div className="d-flex justify-content-end">
                <Button onClick={() => props.alterState("isError", false)} variant="outline-danger">
                    Close
                </Button>
            </div>
        </Alert>
    );

}