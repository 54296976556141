import React, {useEffect, useState} from 'react';

//Creating Context
const AppContext = React.createContext();

//Exporting Provider
export function AppProvider(props) {
    const [state, setState] = useState(
        {
            isAuthenticated: false,
            token: null,
            user: null,
        }
    )

    useEffect(() => {
        let data = window.localStorage.getItem('app_data');
        if (data) {
            setState(JSON.parse(data));
        }
    }, []);

    useEffect(() => {
        window.localStorage.setItem('app_data', JSON.stringify(state));
    });

    return (
        <AppContext.Provider value={{context: state, updateContext: setState}}>
            {props.children}
        </AppContext.Provider>
    );
}

//Exporting Consumer
export const AppConsumer = AppContext.Consumer;

export default AppContext;