import React, {useContext, useState} from "react";
import {Button, Card, Form} from "react-bootstrap";
import APIservices from "../../services/APIservices";
import ErrorPage from "../common/ErrorPage";
import Loader from "../common/Loader";
import AppContext from '../../services/AppProvider'
import {Link} from "react-router-dom";

function LoginForm(props) {
    const {updateContext} = useContext(AppContext);
    const [state, setState] = useState({
        isLoading: false,
        formData: {
            username: "",
            password: ""
        },
        error: {
            show: false,
            data: {
                code: "",
                message: ""
            }
        }
    });

    let alterState = (key, value) => {
        state[key] = value.constructor === String ? value.trim() : value;
        setState({...state})
    };

    let handleUserInput = e => {
        e.preventDefault();
        const {name, value} = e.target;
        let formData = state.formData;
        formData[name] = value
        alterState('formData', formData)
    }

    let inputValidation = () => {
        let isValid = true;

        let errors = props.errors;

        if (state.formData.username.length > 3) {
            errors.username = ""
        } else {
            errors.username = "Invalid Username!"
            isValid = false;
        }

        if (state.formData.password.length > 3) {
            errors.password = ""
        } else {
            errors.password = "Invalid Password"
            isValid = false;
        }

        props.alterState("errors", errors);
        props.alterState("isError", !isValid);
        return isValid;
    }

    let submitForm = async (e) => {
        alterState('isLoading', true);
        e.preventDefault();
        e.stopPropagation();
        let isValid = true;

        if (!inputValidation()) {
            alterState('isLoading', false);
            return false;
        } else {
            const response = await APIservices.login(state.formData);
            if (response.data.data.error === undefined) {
                let data = {
                    token: response.data.meta.token,
                    user: response.data.data,
                    isAuthenticated: true
                };
                updateContext(data);
                window.location.reload();
                return;
            } else {
                let errors = props.errors;
                errors.name = response.data.data.error.message;
                props.alterState("errors", errors);
                isValid = false;
                alterState('isLoading', false);
            }
            props.alterState("isError", !isValid);
        }
    }

    if (state.error.show)
        return <ErrorPage
            code={state.error.data.code}
            message={state.error.data.message}
        />
    else if (state.isLoading)
        return <Loader/>
    else
        return (
            <div>
                <Card>
                    <Card.Header>Login</Card.Header>
                    <Card.Body>

                        <Form>

                            <Form.Group controlId="email">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    name="username"
                                    type="username"
                                    placeholder="Enter Username"
                                    value={state.formData.username}
                                    onChange={handleUserInput}
                                />
                            </Form.Group>

                            <Form.Group controlId="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    name="password"
                                    type="password"
                                    placeholder="Password"
                                    value={state.formData.password}
                                    onChange={handleUserInput}
                                />
                            </Form.Group>

                            <Button variant="primary" type="submit" onClick={submitForm}>
                                Login
                            </Button>
                        </Form>

                    </Card.Body>
                    <Card.Footer className="text-muted text-right">
                        <Link to="forgot-password">Forgot Password</Link>
                    </Card.Footer>
                </Card>
            </div>
        );
}

export default LoginForm