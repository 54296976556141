import React, {useCallback, useEffect, useState} from "react";
import {withRouter} from 'react-router-dom';
import {Card, Table} from "react-bootstrap";
import TableData from "../../common/TableData";
import APIservices from "../../../services/APIservices";
import Loader from "../../common/Loader";


function TenantDashboard(props) {

    let viewFile = async (row, operation) => {
        let url = typeof operation.link === "function" ? operation.link(row) : row[operation.link];
        window.open(url, "_blank");
    }

    const [state, setState] = useState(
        {
            isLoading: true,
            userData: {
                pending: {
                    rent: [],
                    advance: [],
                    maintenance: [],
                    others: []
                },
                tenant_info: {
                    tenant_name: "",
                    property_name: "",
                    tenant_type: "",
                    start_date: "",
                    end_date: "",
                    contact_info: {
                        name: "",
                        number: "",
                        email: ""
                    },
                    tax_info: {
                        pan_no: "",
                        gst_no: ""
                    },
                    billing_reference:"",
                    enhancement: {
                        start_date: "",
                        months: "",
                        percentage: ""
                    },
                    documents: []
                }
            },
            columns: {
                pending: {
                    fields: [
                        {
                            dataField: 'uuid',
                            text: 'UUID',
                            hidden: true,
                        },
                        {
                            dataField: 'invoice_no',
                            text: 'Invoice Number',
                        },
                        {
                            dataField: 'date',
                            text: 'Date'
                        },
                        {
                            dataField: 'amount',
                            text: 'Amount'
                        }
                    ]
                },
                documents: {
                    fields:[
                        {
                            dataField: 'uuid',
                            text: 'UUID',
                            hidden: true,
                        },
                        {
                            dataField: 'date',
                            text: 'Date',
                        },
                        {
                            dataField: 'file_name',
                            text: 'File Name',
                        }
                    ],
                    operation: [
                        {
                            icon: 'fa-download',
                            text: 'Download',
                            link: (row) => {
                                return row.link
                            },
                            onClick: viewFile
                        }
                    ],
                }
            }
        }
    );

    let alterState = useCallback((key, value) => {
        state[key] = value;
        setState({...state})
    }, [state, setState]);

    useEffect(() => {
        APIservices.getDashboardInfo().then((response) => {
            if (response.data.data.error === undefined) {
                alterState('userData', response.data.data);
                alterState('isLoading', false);
            } else {
                console.log(response.data);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (state.isLoading) {
        return (<Loader/>)
    } else {
        return (
            <div>

                <div>
                    <Card>
                        <Card.Header><h3>Pending Payment</h3></Card.Header>
                        <Card.Body>
                            {state.userData.pending.rent.length > 0 &&
                            <CardTable title="Rent" columns={state.columns.pending}
                                       rows={state.userData.pending.rent}/>}
                            {state.userData.pending.maintenance.length > 0 &&
                            <CardTable title="Maintenance" columns={state.columns.pending}
                                       rows={state.userData.pending.maintenance}/>}
                        </Card.Body>
                    </Card>
                </div>

                <div className="clearfix"/>
                <br/>

                <div>
                    <Card>
                        <Card.Header><h3>Tenant Basic Information</h3></Card.Header>
                        <TenantBasicTable tenant={state.userData.tenant_info}/>
                    </Card>
                </div>
                <div className="clearfix"/>
                <br/>

                <div>
                    <Card>
                        <Card.Header><h3>Primary Contact Information</h3></Card.Header>
                        <TenantContactInfoTable tenant={state.userData.tenant_info.contact_info}/>
                    </Card>
                </div>
                <div className="clearfix"/>
                <br/>

                <div>
                    <Card>
                        <Card.Header><h3>Tax Information</h3></Card.Header>
                        <TenantTaxInfoTable tenant={state.userData.tenant_info.tax_info}/>
                    </Card>
                </div>
                <div className="clearfix"/>
                <br/>

                <div>
                    <Card>
                        <Card.Header><h3>Rent Enhancement</h3></Card.Header>
                        <TenantEnhancementInfoTable tenant={state.userData.tenant_info.enhancement}/>
                    </Card>
                </div>
                <div className="clearfix"/>
                <br/>

                <div>
                    <Card>
                        <Card.Header><h3>Documents</h3></Card.Header>
                        <Card.Body>
                            {state.userData.tenant_info.documents.length > 0 &&
                            <CardTable title="" columns={state.columns.documents}
                                       rows={state.userData.tenant_info.documents}/>}
                        </Card.Body>
                    </Card>
                </div>

                <div className="clearfix"/>
                <br/>

            </div>
        );
    }
}

const RenderRow = (props) => {
    return (
        <tr>
            <th scope="row">{props.name}</th>
            <td class={props.className}>{props.value}</td>
        </tr>
    )
}

const TenantBasicTable = (props) => {
    return (
        <div>
            <Table hover responsive="sm" size="sm">
                <tbody>
                <RenderRow name="Tenant Name" value={props.tenant.tenant_name}/>
                <RenderRow name="Property Name" value={props.tenant.property_name}/>
                <RenderRow name="Tenant Type" value={props.tenant.tenant_type}/>
                <RenderRow name="Agreement Start Date" value={props.tenant.start_date}/>
                <RenderRow name="Agreement End Date" value={props.tenant.end_date}/>
                <RenderRow className="text-capitalize" name="Billing Preference" value={props.tenant.billing_reference}/>
                <RenderRow className="text-capitalize" name="Communication Channel" value={props.tenant.com_channel}/>
                </tbody>
            </Table>
        </div>
    );
}

const TenantContactInfoTable = (props) => {
    return (
        <div>
            <Table hover responsive="sm" size="sm">
                <tbody>
                <RenderRow name="Name" value={props.tenant.name}/>
                <RenderRow name="Number" value={props.tenant.number}/>
                <RenderRow name="Email" value={props.tenant.email}/>
                </tbody>
            </Table>
        </div>
    );
}

const TenantTaxInfoTable = (props) => {
    return (
        <div>
            <Table hover responsive="sm" size="sm">
                <tbody>
                <RenderRow name="Pan No" value={props.tenant.pan_no || "N/A"}/>
                <RenderRow name="GST No" value={props.tenant.gst_no || "N/A"}/>
                </tbody>
            </Table>
        </div>
    );
}

const TenantEnhancementInfoTable = (props) => {
    return (
        <div>
            <Table hover responsive="sm" size="sm">
                <tbody>
                <RenderRow name="Start Date" value={props.tenant.start_date}/>
                <RenderRow name="Enhance Every" value={props.tenant.months + ' Months'}/>
                <RenderRow name="Percentage" value={props.tenant.percentage + '%'}/>
                </tbody>
            </Table>
        </div>
    );
}

const CardTable = (props) => {

    return (
        <div>
            <Card.Title>{props.title}</Card.Title>
            <TableData headers={props.columns} rows={props.rows}/>
        </div>
    );
}


export default withRouter(TenantDashboard)