import React, {useEffect, useState} from "react";
import Form from './Form'
import AlertInfo from "../../common/AlertInfo";
import {withRouter} from 'react-router-dom';

function ForgotTenantUserPassword(props) {
    const [state, setState] = useState({
        errors: {
            username: ""
        },
        isError: false,
    });

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    let alterState = (key, value) => {
        state[key] = value.constructor === String ? value.trim() : value;
        setState({...state})
    }

    return (
        <div className="row">
            <div className="col">

                <Form
                    alterState={alterState}
                    errors={state.errors}
                />
                <br/>
                <AlertInfo
                    isError={state.isError}
                    errors={state.errors}
                    alterState={alterState}
                />

            </div>
        </div>
    );
}

export default withRouter(ForgotTenantUserPassword)